body.white{
    color: #707070;
    background-color: #FFFFFF;

    .logo-img{
        margin-bottom: 10px;
    }

    .logo-text-outline, .logo-text, .logo-text:hover, .logo-text:active, .navbar-custom .topnav-menu .nav-link {
        color: #202020!important;
    }

    .logo-text-outline, .logo-text, .logo-text:hover, .logo-text:active {
        margin-bottom: 40px;
    }

    .navbar-custom .app-search .form-control {
        color: #707070;
        background-color: rgba(255, 255, 255, 1);
        border: none;
    }

    .page-title-box .page-title, .b-title{
        color: #343a40;
    }

    .btn-primary, .btn-black, .btn-outline-primary:hover{
        color: #fff;
        background-color:#2CA3F8;
        border-color:#2CA3F8;
    }

    .card-pricing{
        .btn-success{
            color: #fff;
            background-color:#2CA3F8;
            border-color:#2CA3F8;
        }
        .text-success{
            color:#2CA3F8!important;
        }
    }

    .MuiSwitch-colorSecondary.Mui-checked{
        color: #2CA3F8;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #2CA3F8;
    }

    ol.progtrckr li.progtrckr-done {
        color: #2CA3F8;
        border-bottom: 4px solid #2CA3F8;
    }
      
    ol.progtrckr li.progtrckr-done:before {
        background-color: #2CA3F8;
      }

    .text-primary{
        color:#2CA3F8!important;
    }

    .btn-outline-black, .btn-outline-primary{
        color:#2CA3F8;
        border-color:#2CA3F8;
    }

    .btn-success {
        color: #fff;
        background-color: #108F64;
        border-color: #108F64;
    }

    .form-control, .btn, .card{
        border-radius: 0px;
    }

    .account-pages{
        margin-top: 7%!important;
        .form-control{
            padding: 22px 13px;
            line-height: 20px;
        }
        .btn{
            padding: 13px 13px;
            line-height: 20px;
            box-shadow: none;
        }
        .card{
            box-shadow: unset;
            
            .p-4{
                padding: 0px!important;
            }

            .reg-desc{
                display: none;
            }
        }
    }

    .spinner-loader > div {
        background-color:#2CA3F8;
    }

    .register-page{
        margin-top: 7%!important;
    }

    .btn{
        box-shadow: none;
    }

    .btn:focus{
        outline: none!important;
    }

    .navbar-custom {
        background-color: rgba(255, 255, 255, 1)!important;
    }

    .nav-user img{
        height: 24px;
        width: 24px;
    }

    .btn-help {
        margin-top: 8px;
        i{
            font-size: 24px;
        }
    }
       
    .hdr-search-text{
        color:#2CA3F8;
        font-weight:300;
    }

    .card-pricing.recommended{
        border: 2px solid#2CA3F8;

        .btn-outline-black{
            color: #fff;
            background-color:#2CA3F8;
            border-color:#2CA3F8;
        }
    }

    .MuiTableCell-root{
        font-family: unset;

        span.onbackorder{
            padding: 5px 10px;
            background-color:rgba(252,192,21,.25);
            color: #fcc015;
            font-weight: 200;
        }

        span.instock{
            padding: 2px 10px;
            background-color: rgba(68,207,156,.25);
            color: #44cf9c;
            font-weight: 200;
        }

        span.outofstock{
            padding: 5px 10px;
            background-color: rgba(248,98,98,.25);
            color: #f86262;
            font-weight: 200;
        }
    }

    .MuiTableContainer-root{
        box-shadow: unset;

        .MuiTableCell-head {
            color: #323a46;
            background-color: #f7f8f9;
            font-weight: 300;
        }
    
        .MuiTableCell-body {
            color: #6c757d;
            font-weight: 200;
        }
    }

    .MuiTableBody-root{
        min-height: 630px;
    }

    .MuiTablePagination-root{
        color: unset;
    }

    .MuiTablePagination-caption, .MuiInputBase-root{
        font-family: unset;
        font-weight: 200;
    }

    .MuiTypography-h6{
        color: #323a46;
        font-family: unset;
        font-weight: 300;
        font-size: 15px;
    }

    .MuiTableCell-sizeSmall{
        background-color: white!important;
    }

    .MuiAccordion-rounded{
        border: unset!important;
        box-shadow: unset!important;

        .form-group{
            width: 60%;
        }

        .MuiAccordionSummary-content.Mui-expanded {
            margin: 20px 0 10px;
        }
    }

    .tableItem{
        .MuiTableCell-root{
            border-bottom: unset;

            span.pending{
                padding: 5px 10px;
                background-color:rgba(67,190,225,.25);
                color: #43bee1;
                font-weight: 200;
            }

            span.processing{
                padding: 5px 10px;
                background-color:rgba(252,192,21,.25);
                color: #fcc015;
                font-weight: 200;
            }
            
            span.on-hold{
                padding: 5px 10px;
                background-color: rgba(102,88,221,.18);
                color: #6658dd;
                font-weight: 200;
            }
            
            span.completed{
                padding: 2px 10px;
                background-color: rgba(68,207,156,.25);
                color: #44cf9c;
                font-weight: 200;
            }
            
            span.cancelled{
                padding: 5px 10px;
                background-color: rgba(108,117,125,.18);
                color: #6c757d;
                font-weight: 200;
            }
            
            span.refunded{
                padding: 5px 10px;
                background-color: rgba(50,131,246,.18);
                color: #3283f6;
                font-weight: 200;
            }
            
            span.failed{
                padding: 5px 10px;
                background-color: rgba(248,98,98,.25);
                color: #f86262;
                font-weight: 200;
            }
        }
    }
}