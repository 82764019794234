//
// custom-select.scss
//

[data-plugin="customselect"] {
    display: none;
}

.nice-select {
    line-height: 36px;
    height: 38px;
    border-color: $input-border-color;
    border-radius: $input-border-radius;
    box-shadow: $input-box-shadow;
}

.nice-select.open, .nice-select:active, .nice-select:focus {
    border-color: $input-focus-border-color;
}

.nice-select.small {
    height: 32px;
    line-height: 30px;
}